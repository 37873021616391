<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" content="复诊记录">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <el-descriptions class="margin-top" :column="3" border>
                    <el-descriptions-item label="患者姓名">{{patientDetail.name}}</el-descriptions-item>
                    <el-descriptions-item label="年龄">{{patientDetail.age}}岁</el-descriptions-item>
                    <el-descriptions-item label="手机号">{{patientDetail.phone}}</el-descriptions-item>
                    <el-descriptions-item label="身份证号">{{patientDetail.idNo}}</el-descriptions-item>
                    <el-descriptions-item label="联系地址">{{patientDetail.address}}</el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="iq-card-bodys record-box">
                <el-row :gutter="30" class="height1">
                    <el-col :span="8" class="height1">
                        <div class="recordNum">当前患者有 <span class="red">{{diagnoseList.length}}</span> 条就诊记录</div>
                        <el-scrollbar class="left-nav-wrap" style="height: calc(100% - 50px);">
                        <ul class="recordList">
                            <li class="record-item" @click="getcur(item.id)" :class="cursub== item.id ? 'active':''" v-for="(item,index) in diagnoseList" :key="index">
                                <div class="clearfix">
                                    <span class="left record-date">{{item.addTime | timefilters}}</span>
                                    <span class="right record-name">{{item.diseaseDiagnose}}</span>
                                </div>
                                <p class="record-info">主诉：{{item.main}}</p>
                                <p class="prescribe-btn">
                                    <el-button size="mini" type="success" @click="toPrescribe(item)" plain>开具处方</el-button></p>
                            </li>
                        </ul>
                        </el-scrollbar>
                    </el-col>
                    <el-col :span="16" class="height1">
                        <el-tabs v-model="activeName" type="card" class="tabs-box" @tab-click="tabClick(DiagnoseDetial)">
                            <el-tab-pane label="复诊信息" name="first">
                                <div class="record-mes height1">
                                    <el-scrollbar class="left-nav-wrap" style="height: calc(100% - 10px);">
                                        <el-form label-width="100px">
                                            <el-form-item label="主诉：">
                                                <p>{{DiagnoseDetial.main}}</p>
                                            </el-form-item>
                                            <el-form-item label="现病史：">
                                                <p v-if="DiagnoseDetial.current">{{DiagnoseDetial.current}}</p>
                                                <p v-else>(空)</p>
                                            </el-form-item>
                                            <el-form-item label="既往史：">
                                                <p v-if="DiagnoseDetial.past">{{DiagnoseDetial.past}}</p>
                                                <p v-else>(空)</p>
                                            </el-form-item>
                                            <el-form-item label="过敏史：">
                                                <p v-if="DiagnoseDetial.irritability">{{DiagnoseDetial.irritability}}</p>
                                                <p v-else>(空)</p>
                                            </el-form-item>
                                            <el-form-item label="家族史：">
                                                <p v-if="DiagnoseDetial.family">{{DiagnoseDetial.family}}</p>
                                                <p v-else>(空)</p>
                                            </el-form-item>
                                            <el-form-item label="疾病诊断：">
                                                <el-input type="textarea" :value="DiagnoseDetial.diseaseDiagnose" disabled></el-input>
                                            </el-form-item>
                                            <el-form-item label="症状照片：">
                                                <div>
                                                    <el-image
                                                            v-for="item in DiagnoseDetial.diagnoseImgList"
                                                            style="width: 100px; height: 100px;margin-right: 15px;"
                                                            :src="http + item.filePath"
                                                            :preview-src-list='[http + item.filePath]'
                                                            fit="fill"></el-image>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="上传处方：">
                                                <div>
                                                    <el-image
                                                            v-for="item in DiagnoseDetial.prescriptionImgList"
                                                            style="width: 100px; height: 100px;margin-right: 15px;"
                                                            :src="http + item.filePath"
                                                            :preview-src-list='[http + item.filePath]'
                                                            fit="fill"></el-image>
                                                </div>
                                            </el-form-item>
                                        </el-form>
                                    </el-scrollbar>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="处方信息" name="second">
                                <div class="record-mes height1">
                                    <el-scrollbar class="left-nav-wrap" style="height: calc(100% - 10px);">
                                        <el-empty v-if="RXData.length == 0" :image-size="200"></el-empty>
                                        <el-collapse v-else>
                                        <el-collapse-item v-for="(item,index) in RXData" :key="index">
                                            <template slot="title">
                                                <p>
                                                    <template>
                                                        <el-tag v-if="item.auditStatus == -1" type="danger"  size="small">驳回</el-tag>
                                                        <el-tag v-if="item.auditStatus == 0" type="warning"  size="small">待审核</el-tag>
                                                        <el-tag v-if="item.auditStatus == 1" type="success"  size="small">审核通过</el-tag>
                                                        <el-tag v-if="item.auditStatus == 2" type="primary"  size="small">待取药</el-tag>
                                                        <el-tag v-if="item.auditStatus == 3" type="info"  size="small">已完成</el-tag>
                                                    </template>
                                                    处方名称：{{item.prescriptionName}}</p>
                                                <p class="dataTime">{{item.addTime | timefilters}}</p>
                                            </template>
                                            <el-table :data="item.patientPrescriptionList" :header-cell-style="{background:'#F5F7FC'}">
                                                <el-table-column prop="compositionName" label="成分" width="180">
                                                    <template slot-scope="scope">
                                                        <div v-html="scope.row.patientComposition.compositionName"></div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="dosage" label="用量" width="180">
                                                </el-table-column>
                                                <el-table-column prop="remark" label="炮制方法">
                                                </el-table-column>
                                            </el-table>
                                        </el-collapse-item>
                                    </el-collapse>
                                    </el-scrollbar>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    import {Patient} from "../../../components/HospitalDomain/Patient/Patient";
    import {Herbal} from "../../../components/HospitalDomain/Patient/Herbal";

    export default {
        name: "DiagnosticRecord",
        data(){
            var patient = new Patient(this.TokenClient, this.Services.Authorization)
            var herbal = new Herbal(this.TokenClient, this.Services.Drug)
            return{
                patientDomain: patient,
                herbalDomain: herbal,
                id: this.$route.query.id, //列表页id
                patientDetail:{},
                diagnoseList:[],
                DiagnoseDetial:{},
                cursub: -1,
                http: this.Services.Authorization,
                activeName: 'first',
                RXData: [],
            }
        },
        mounted() {
            if(this.id){
                this.getDetail()
            }
            // this.getDiagnoseList()
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            getDetail(){
                var _this = this;
                _this.patientDomain.GetDetail(_this.id,
                    function (data) {
                        _this.patientDetail = data.data;
                        _this.diagnoseList = data.data.patientDiagnose;
                        _this.cursub = _this.diagnoseList[0].id;
                        _this.getcur(_this.cursub)
                    },
                    function (err) {

                    });
            },
            /*getDiagnoseList(){
                var _this = this;
                _this.patientDomain.DiagnoseList(1,
                    function (data) {

                    },
                    function (err) {

                    });
            },*/
            tabClick(item){
                var _this = this;
                console.log(_this.activeName)
                if(_this.activeName == "first"){
                    this.getcur(item.id)
                }else if(_this.activeName == "second"){
                    this.getRX(item)
                }

            },
            getcur(i){
                var _this = this;
                this.cursub = i
                _this.activeName = "first";
                console.log(_this.activeName)
                _this.patientDomain.DiagnoseDetial(i,
                    function (data) {
                        _this.DiagnoseDetial = data.data;
                    },
                    function (err) {

                    });

            },
            getRX(i){
                var _this = this;
                _this.herbalDomain.GetPatientPrescription(_this.id,i.id,-999,
                    function (data) {
                        _this.RXData = data.data
                    },
                    function (err) {
                        _this.RXData = [];
                        console.log(_this.RXData)
                    });
            },
            toPrescribe(item){
                this.$router.push({
                    path: '/Patient/Prescribing',
                    query:{
                        patientId: item.patientId,
                        patientDiagnoseId: item.id
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .iq-card-bodys {
        padding: 15px;
        border-radius: 15px;
        background: #FFFFFF;
        margin-bottom: 20px;
    }
    .el-page-header{
        line-height: 73px;
        padding-left: 20px;
    }
    /*content*/

    .iq-card-body {
        margin-bottom: 20px;
    }
    .record-box{
        height: calc(100% - 135px);
    }
    .recordNum{
        padding: 0 10px;
        background-color: #fafafa;
        border: 1px solid #EBEEF5;
        height: 36px;
        line-height: 36px;
        border-radius: 4px;
        margin-bottom: 20px;
    }
    .red{
        color: red;
    }
    .bg-purple {
        border:1px solid #d3dce6;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .record-item{
        position: relative;
        border-radius: 4px;
        border: 1px solid #EBEEF5;
        padding: 12px 10px;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .record-item:hover{
        border: 1px solid var(--baseColor);
    }
    .record-item.active{
        border: 1px solid var(--baseColor);
        background-color: var(--baseColor);
        color: #ffffff;
    }
    .record-item.active .record-name{
        color: yellow;
    }
    .record-item .prescribe-btn{
        position: absolute;
        right: 10px;
        top: 50px;
    }
    .record-item .prescribe-btn:hover{
        text-decoration: underline;
    }
    .record-info{
        margin-right: 100px;
        margin-top: 15px;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
    }
    .record-name{
        color: var(--baseColor);
    }
    .record-mes{
        border-radius: 4px;
        border: 1px solid #EBEEF5;
        padding: 12px 10px;
    }
    .tabs-box{
        height: 100%;
    }

    .tabs-box .el-tab-pane{
        height: 100%;
    }
    .dataTime{
        position: absolute;
        right: 30px;
    }
</style>
<style>
    .tabs-box .el-tabs__content{
        height: calc(100% - 56px);
    }
</style>
